import React from "react";
import Styles from "./styles/pr.module.css";
import FlackImageSmall from "./images/flack_memorial_christian_union-cropped-small.jpg";
import {Link} from "react-router-dom";

const FlackMemorial = (props) => {
    document.title = "HAOC - " + props.page_title;

    return (<div>
        <h2>Flack Memorial Church</h2>
        <div className={Styles.wrapped_image}><img src={FlackImageSmall} /></div>
        <div>
            <div className={Styles.haoc_larger_text_paragraph}>
                Flack Memorial is a church with a great legacy of which we are proud. That being said, we do not
                feel the need to preserve the church as if it were a museum. Feel free to leave the “Sunday best” at
                home. Dress how you feel comfortable. Upon arriving at Flack you will find friendly faces, a
                contemporary worship style, and a message that is relevant and timely.
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Pastor</b>: Marion Hunerdosse
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Service Times</b>:
                <div>9:30am - Sunday School</div>
                <div>10:45am - Worship Service</div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Location</b>:
                <div>1300 Tracy Avenue</div>
                <div>Excelsior Springs, MO 64024</div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Online at:</b>
                <div><Link to={"https://www.facebook.com/flackmemorial"}>Flack Memorial Church on
                    Facebook</Link></div>
                <div>Website: <Link to={"https://www.flackmemorial.com"}>FlackMemorial.com</Link></div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Contact Info:</b>
                <div>816-630-6234</div>
                <div>flackchurch@gmail.com</div>
            </div>
            <div>
                <iframe
                    title="flack_google_map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1774.003404874771!2d-94.24133267052369!3d39.35505251391255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c1af65c7ebf259%3A0xef8b32116ace265e!2sFlack%20Memorial%20Christian%20Church!5e1!3m2!1sen!2sus!4v1722378174926!5m2!1sen!2sus"
                    width="600" height="450" allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>)
}
export default FlackMemorial;