import React from "react";
import Styles from "./styles/pr.module.css"
import Logo from "./images/heartlandaoc.jpg"
import {Link} from "react-router-dom";

const AboutUs = (props) => {
    document.title = "HAOC - " + props.page_title;

    return (<div>
        <h2>About Us</h2>
        <div className={Styles.wrapped_image}><img src={Logo} /></div>
        <div>
            <div className={Styles.haoc_larger_text_paragraph}>
                Welcome to the website of the Heartland Association of Churches.
                We are a group of like-minded churches with a long history of fellowship and cooperation.
                We have recently linked ourselves together in this unique association apart from denominational
                encumbrances.  Our goal is to be a support and encouragement to each other while increasing our
                ministry effectiveness through joining resources and combining efforts.
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                The cultural environment of our day seems to portend increasing challenges for those in the
                evangelical Christian community.  Developing greater solidarity with one another seems a wise
                course of action in these tumultuous times as we seek to serve and advance God’s kingdom.
                Our arms are open to churches that see the need for fellowship and cooperative mission to link
                with us without relinquishing their identity or chosen church polity.  We believe that together
                we can more effectively fulfill our all-important purpose of living out a “faith expressing itself
                in love.” (Galatians 5:6) Let us link hearts and hands that the Good News of Jesus might flow out
                of that unity to the world around us.
            </div>
        </div>
    </div>)
}
export default AboutUs;