import React from "react";
import Styles from "./styles/pr.module.css";
import BethelSmall from "./images/bethel_christian_union-cropped-small.jpg";
import BethelLarge from "./images/bethel_christian_union-cropped.jpg";
import {Link} from "react-router-dom";

const Bethel = (props) => {
    document.title = "HAOC - " + props.page_title;

    return (<div>
        <h2>Bethel Church</h2>
        <div className={Styles.wrapped_image}><img src={BethelSmall} /></div>
        <div>
            <div className={Styles.haoc_larger_text_paragraph}>
                Bethel Church is a Christ-centered, Bible-preaching church in Ray County, Missouri.
                It was established 1872 at the
                Knoxville School. The church building itself was built in 1881.
                Pastor Seth McCubbin preaches every Sunday.
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                “But grow in the grace and knowledge of our Lord and Savior Jesus Christ.
                To him be glory both now and forever! Amen.” 2 Peter 3:18
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Pastor</b>: Seth McCubbin
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Service Times</b>:
                <div>10am - Sunday School</div>
                <div>11am - Worship Service</div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Location</b>:
                <div>5 miles south of Polo, Mo. on Highway 13</div>
                <div>3 miles north of Knoxville, Mo. On Highway 13</div>
                <div>1 mile west off of Highway 13 on 220th Street (See Sign)</div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Online at:</b>
                <div><Link to={"https://www.facebook.com/profile.php?id=61557744556773"}>Bethel Church on
                    Facebook</Link></div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Contact Info:</b>
                <div>(816) 592-9807</div>
                <div>sethmccubbin2019@gmail.com</div>
            </div>
            <div>
                <iframe
                    title="bethel_map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3844.8573838390425!2d-94.03811628781418!3d39.48980788713564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c19671fca6b2d7%3A0xd17c2f9fc093643!2s37986%20W%20220th%20St%2C%20Polo%2C%20MO%2064671!5e1!3m2!1sen!2sus!4v1722261550368!5m2!1sen!2sus"
                    width="600" height="450" allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>)
}
export default Bethel;