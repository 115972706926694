import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";

const StatementOfFaith = (props) => {
    document.title = "HAOC - " + props.page_title;
    return (<div>
        <h2>{props.page_title}</h2>
        <div>
            <div>
                <div className={Styles.pr_heading_medium}>We Believe</div>
                <div>
                    <ul className={Styles.haoc_statement_of_faith}>
                        <li>We believe the Bible to be the inspired, the only infallible,
                            authoritative Word of God.</li>
                        <li>We believe that there is one God, eternally existent in three persons:
                            Father, Son and Holy Spirit.</li>
                        <li>We believe in the deity of our Lord Jesus Christ, in His virgin birth, in His sinless life,
                            in His miracles, in His teachings, in His vicarious and atoning death through His shed
                            blood, in His bodily resurrection, in His ascension to the right hand of the Father,
                            and in His personal return in power and glory.</li>
                        <li>We believe salvation is by grace alone, through faith alone, in Christ alone.</li>
                        <li>We believe that for the salvation of lost and sinful people, regeneration by the
                            Holy Spirit is absolutely essential.</li>
                        <li>We believe in the present ministry of the Holy Spirit by whose indwelling the
                            Christian is enabled to live a godly life.</li>
                        <li>We believe in the resurrection of both the saved and the lost; they that are saved
                            unto the resurrection of life and they that are lost unto the resurrection of eternal
                            punishment and separation from God.</li>
                        <li>We believe in the spiritual unity of believers in our Lord Jesus Christ.</li>
                        <li>The second coming of the Lord Jesus Christ is imminent and is the believer’s blessed
                            hope and a vital truth which is an incentive to holy living and faithful service.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>)
}
export default StatementOfFaith;