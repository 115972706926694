import React from "react";
import Styles from "./styles/pr.module.css";
import PRSmall from "./images/prairie_ridge_christian_union-cropped-small.jpg";
import PRLarge from "./images/prairie_ridge_christian_union-cropped.jpg";
import PastorsPhone from "./images/pastors_phone.jpg";
import {Link} from "react-router-dom";

const PrairieRidge = (props) => {
    document.title = "HAOC - " + props.page_title;

    return (<div>
        <h2>Prairie Ridge Church</h2>
        <div className={Styles.wrapped_image}><img src={PRSmall} /></div>
        <div>
            <div className={Styles.haoc_larger_text_paragraph}>
                Prairie Ridge Church is a non-denomination Christian church. Our desire is to build up the church
                in love and knowledge of God and to equip one another to make disciples in the world.
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                In our church services, we aim to worship the Lord through prayer, praise and proclamation of
                the Word of God.
                View our <Link to={"https://prairieridge.church/statement_of_faith"}>Statement of Faith</Link> on our
                website to see the essentials of what we believe.
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                "I have been crucified with Christ and I no longer live, but Christ lives in me.
                The life I now live in the body, I live by faith in the Son of God, who loved me and gave
                himself for me.” Galatians 2:20
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Pastor</b>: Mike Turner
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Service Times</b>:
                <div>9:30am - Sunday School</div>
                <div>10:30am - Worship Service</div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Location</b>:
                <div>5 miles west of Polo, Mo. on Highway 116</div>
                <div>6951 SW 116 Hwy</div>
                <div>Polo, MO 64671</div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Online at:</b>
                <div><Link to={"https://www.facebook.com/theprairieridge"}>Prairie Ridge Church on
                    Facebook</Link></div>
                <div>Website: <Link to={"https://www.prairieridge.church"}>PrairieRidge.church</Link></div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Contact Info:</b>
                <div><img src={PastorsPhone} /></div>
                <div>theprairieridge@gmail.com</div>
            </div>
            <div>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3841.2521175937754!2d-94.135591423483!3d39.554960707637576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c1bf3e515188c7%3A0x7f74acb590635200!2s6951%20MO-116%2C%20Polo%2C%20MO%2064671!5e1!3m2!1sen!2sus!4v1722279264143!5m2!1sen!2sus"
                    width="600" height="450"  allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>)
}
export default PrairieRidge;