import React from "react";
import Styles from "./styles/pr.module.css"
import Logo from "./images/heartlandaoc.jpg"
import {Link} from "react-router-dom";

const JoiningHeartland = (props) => {

    document.title = "HAOC - " + props.page_title;

    return (<div>
        <h2>Joining Heartland</h2>
        <div className={Styles.wrapped_image}><img src={Logo} />
        </div>
        <div>
            <div className={Styles.haoc_larger_text_paragraph}>
                Heartland Association of Churches is not a denomination with ruling authority over member churches. It is
                an association dedicated to mutual support, edification, accountability, and corporate ministry.
                The Heartland Association provides <Link to="../ministry_credentialing">credentials</Link> and mentoring for
                pastors. Membership
                is voluntary and churches are free to leave with no penalty. The association is funded by the voluntary
                contributions of individuals and churches. There are no mandatory dues.
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                If you are an independent church that is interested in joining with the Heartland Association of
                Churches, please contact us at <a href="mailto:contact@heartlandaoc.com">contact@heartlandaoc.com</a>.
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                Membership is open to any church that affirms the <Link to="../statement_of_faith">Statement of
                Faith</Link>.
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                Heartland churches are also eager to work with other churches, church leaders, and other believers
                to partner in various works of ministry in our communities and the larger world.
            </div>
            <div className={Styles.haoc_larger_text_paragraph}></div>
        </div>
    </div>)
}
export default JoiningHeartland;