import React, {useEffect, useState} from "react";
import DStyles from "./styles/discipleship.module.css";
import Background from "./images/devotional_background_2.jpg";
import Audio from "./devotional/mike_polo_devotional_audio_0001.mp3";

const Devotional = (props) => {
    document.title = "HAOC - " + props.page_title;

    const [verse, setVerse] = useState("Elijah lay down and slept under the broom tree. " +
        "But as he was sleeping an angel touched him and said, 'Get up and eat.'");
    const [verseRef, setVerseRef] = useState("1 Kings 19:4-8");
    const [title, setTitle] = useState("Rest for the Weary");
    const [body, setBody] = useState("Elijah was spiritually and physically spent. That is always fertile ground for Satan and his whispers of discouragement. In Elijah’s case, it sunk its roots into his weariness. But God knew the limits and lethargy of his worn-out servant. And so, He steps in with rest and resources. Rest is a consistent theme of God in His dealings with those who walk with Him. He understands the weaknesses of our body and soul and the wearying nature of circumstantial challenges. He will faithfully provide the rest and renewal we need that will cause discouragement to give way to restored vision and vitality. Jesus issued such an invitation to the worn and weary to find relief from the burden of discouragement by yielding to His will and way. (Luke 11:28-30) The offer still stands. Rest and refreshment are readily available in the presence of our Good Shepherd. When your journey gets long and burdensome, draw close to Him and receive renewal in His loving presence.");

    return <div>
        <h1 className={DStyles.mpd_title}>{title}</h1>
        <div
            className={DStyles.mpd_verse_holder_div}
            style={{backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat"}}>
            <div className={DStyles.mpd_verse_ref_caps}>{verseRef}</div>
            <div className={DStyles.mpd_verse_large}>{verse}</div>
        </div>
        <div className={DStyles.devotional_audio_div}>
            Listen:
            <audio controls>
                <source src={Audio} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </div>
        <div className={DStyles.mpd_body}>
            {body}
        </div>
    </div>
}

export default Devotional;