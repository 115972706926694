import React from "react";
import Styles from "./styles/pr.module.css";
import RichmondSmall from "./images/richmond_christian_union_church-cropped-small.jpg";
import {Link} from "react-router-dom";

const Richmond = (props) => {
    document.title = "HAOC - " + props.page_title;

    return (<div>
        <h2>Richmond Christian Union</h2>
        <div className={Styles.wrapped_image}><img src={RichmondSmall} /></div>
        <div>
            <div className={Styles.haoc_larger_text_paragraph}>
                Richmond Christian Union is a family of believers worshiping God together and learning from his Word.
                The Bible is taught, and God is worshiped as He's revealed in His Word.
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                "To Love God is to Obey God."
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Pastor</b>: Mike Polo
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Service Times</b>:
                <div>9:30am – Sunday School</div>
                <div>10:45am Worship Service</div>
                <div>Wednesday 6:45pm Bible Study</div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Location</b>:
                <div>415 N. Main Street</div>
                <div>Richmond, MO 64085</div>
            </div>
            <div>
                <iframe
                    title="richmond_google_map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d583.6735960974006!2d-93.9695291663015!3d39.27887914822275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c19dc0cc17e9ad%3A0xde2cb9e11bd106aa!2s415%20E%20N%20Main%20St%2C%20Richmond%2C%20MO%2064085!5e1!3m2!1sen!2sus!4v1722379852023!5m2!1sen!2sus"
                    width="600" height="450" allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>)
}
export default Richmond;