import React from "react";
import Styles from "./styles/pr.module.css";
import HVSmall from "./images/hidden_valley_christian_union-cropped-small.jpg";
import {Link} from "react-router-dom";

const HiddenValley = (props) => {
    document.title = "HAOC - " + props.page_title;

    return (<div>
        <h2>Hidden Valley Church</h2>
        <div className={Styles.wrapped_image}><img src={HVSmall} /></div>
        <div>
            <div className={Styles.haoc_larger_text_paragraph}>
                Hidden Valley Church is a family of believers worshiping God together and learning from his Word.
                The Bible is taught, and God is worshiped as He's revealed in His Word.
            </div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Pastor</b>: Curtis Romang
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Service Times</b>:
                <div>9am - Worship Service</div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Location</b>:
                <div>17500 Hidden Valley Road</div>
                <div>Independence, MO 64057</div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Online at:</b>
                <div><Link to={"https://www.facebook.com/hiddenvalleychurch"}>Hidden Valley Church on
                    Facebook</Link></div>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <b>Contact Info:</b>
                <div>(816) 730-9481</div>
                <div>c.romang@yahoo.com</div>
            </div>
            <div>
                <iframe
                    title="hidden_valley_google_map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d585.4589471425146!2d-94.37394352669465!3d39.06410271844285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c11d3717617aef%3A0x2dd3bfbdbfe36461!2s17500%20Hidden%20Valley%20Rd%2C%20Independence%2C%20MO%2064057!5e1!3m2!1sen!2sus!4v1722379075061!5m2!1sen!2sus"
                    width="600" height="450" allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>)
}
export default HiddenValley;