import React from "react";
import Styles from "./styles/pr.module.css"
import Logo from "./images/heartlandaoc.jpg"
import {Link} from "react-router-dom";

const FindChurch = (props) => {
    document.title = "HAOC - " + props.page_title;


    return (<div>
        <h2>Find a Church</h2>
        <div className={Styles.wrapped_image}><img src={Logo} /></div>
        <div>
            <div className={Styles.haoc_larger_text_paragraph}>
                Contact us at <a href="mailto:contact@heartlandaoc.com">contact@heartlandaoc.com</a> and we would
                love to help you find a local church, either a church in our association or out of it. We are
                delighted when believers in Christ are worshiping and serving together with the Body of Christ.
            </div>
        </div>
    </div>)
}
export default FindChurch;