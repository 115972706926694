import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";
import PastorPhone from "./images/pastors_phone.jpg";

const ContactUs = (props) => {
    document.title = "HAOC - " + props.page_title;

    return (<div>
        <div>
            <div className={Styles.pr_heading_medium}>Contact Information</div>
            <div className={`${Styles.pr_block_quote} ${Styles.pr_font_16}`}>
                <p><span className={Styles.pr_bold}>Board President</span> Mike Polo</p>
                <p><span className={Styles.pr_bold}>Email: </span><a href="mailto:contact@heartlandaoc.com">contact@heartlandaoc.com</a></p>
                <p><span className={Styles.pr_bold}>Facebook: </span> <Link to="https://www.facebook.com/heartlandoac">Heartland Association of Churches</Link></p>
            </div>
        </div>
    </div>)
}
export default ContactUs;