import logo from './logo.svg';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./Layout";
import Home from "./Home";
//import Directory from "./Directory";
import AboutUs from "./about_us";
import StatementOfFaith from "./statement_of_faith";
import Heartland from "./heartland";
import NewHopeMissions from "./new_hope_missions";
import ContactUs from "./contact_us";
import {load_fetch} from "./pr_utils";
import {useEffect, useState} from "react";
import {AuthContext} from "./AuthContext";
import PrintDirectory from "./print_directory";
import Testimony from "./testimony";
import Bethel from "./bethel";
import PrairieRidge from "./prairie_ridge";
import FlackMemorial from "./flack_memorial";
import HiddenValley from "./hidden_valley";
import Richmond from "./richmond";
import FindChurch from "./find_a_church";
import JoiningHeartland from "./joining_heartland";
import MinistryCredentialing from "./ministry_credentialing";
import Leaders from "./leaders";
import Devotional from "./devotional";


function App() {
  const [authStatus, setAuthStatus] = useState(false);
  const authenticationStatus = {authStatus, setAuthStatus};
  const [userInfo, setUserInfo] = useState(false);
  const [canViewDirectory, setCanViewDirectory] = useState(false);

  useEffect(() => {
    check_authentication_status();
  }, []);

  useEffect(() => {
    //console.log(authStatus);
  }, [authStatus])

  const check_authentication_status = () => {
    let url = 'Security_Handler.php';
    let postData = {
      operation: 'CHECK_AUTHENTICATION_STATUS'
    }
    load_fetch(url, postData, (data) => {
        console.log("Checking Auth Status");
      if(!data['ERROR'] && !data['error']) {
          setUserInfo(data);
          if(data['role'] === 'ADMIN' ||
              data['role'] === 'DIRECTORY_EDITOR' ||
              data['role'] === 'DIRECTORY_VIEWER') {
              setCanViewDirectory(true);
          }
          //console.log("Logged in.");
          setAuthStatus(data);
      } else {
          //console.log("Not logged in.");
          setAuthStatus(false);
      }
    });
  }

  const clear_login_information = () => {
      setAuthStatus(false);
  }
  const update_user_info = (new_info) => {
      setUserInfo(new_info);
  }

  if(window.location.pathname.indexOf("print_directory") !== -1) {
      return (
          <AuthContext.Provider value={authenticationStatus}>
          <BrowserRouter>
              <Routes>
                  <Route
                      path="/print_directory"
                      element={<PrintDirectory can_view_directory={canViewDirectory} />} />
              </Routes>
          </BrowserRouter>
          </AuthContext.Provider>
      )
        //return <Navigate to='./haoc_api/Print_Directory.php' replace={true} />

  } else {
      return (
          <AuthContext.Provider value={authenticationStatus}>
              <BrowserRouter>
                  <Routes>
                      <Route path="/" element={<Layout update_user_info={update_user_info}
                                                       app_logout_function={clear_login_information}
                                                       canViewDirectory={canViewDirectory}
                      />}>
                          <Route index element={<Home page_title="Home"/>}/>
                          <Route path="about_us" element={<AboutUs page_title="About Us"/>}/>
                          <Route path="leaders" element={<Leaders page_title="Association Board"/>}/>
                          {/*<Route path="directory"*/}
                          {/*       element={<Directory user_info={userInfo} page_title="Church Directory"/>}/>*/}
                          <Route path="statement_of_faith"
                                 element={<StatementOfFaith page_title="Statement of Faith"/>}/>
                          <Route path="heartland"
                                 element={<Heartland page_title="Heartland Association of Churches"/>}/>
                          <Route path="new_hope_missions" element={<NewHopeMissions page_title="New Hope Missions"/>}/>
                          <Route path="contact_us" element={<ContactUs page_title="Contact Us"/>}/>
                          <Route path="testimony" element={<Testimony page_title="Testimony"/>}/>
                          <Route path="bethel" element={<Bethel page_title="Bethel Church"/>}/>
                          <Route path="prairie_ridge" element={<PrairieRidge page_title="Prairie Ridge Church"/>}/>
                          <Route path="flack" element={<FlackMemorial page_title="Flack Memorial Church"/>}/>
                          <Route path="hidden_valley" element={<HiddenValley page_title="Hidden Valley Church"/>}/>
                          <Route path="richmond" element={<Richmond page_title="Richmond Christian Union"/>}/>
                          <Route path="find_a_church" element={<FindChurch page_title="Find a Church"/>}/>
                          <Route path="joining_heartland" element={<JoiningHeartland page_title="Joining Heartland"/>}/>
                          <Route path="ministry_credentialing" element={<MinistryCredentialing page_title="Ministry Credentialing"/>}/>
                          <Route path="devotional" element={<Devotional page_title="Devotional"/>}/>
                      </Route>
                  </Routes>
              </BrowserRouter>
          </AuthContext.Provider>
      );
  }
}

export default App;
