import React from "react";
import Slideshow from "./slideshow";
import Styles from "./styles/pr.module.css"
import LearnMore from "./images/learn_more.jpg";
import FindAChurch from "./images/find_a_church.jpg";
import JoinIn from "./images/join_in.jpg";
import {Link} from "react-router-dom";


const Home = (props) => {
    document.title = "HAOC - " + props.page_title;

    return (
        <div className={Styles.pr_home_wrapper}>
            <h2>Welcome to Heartland Association of Churches</h2>
            <div className={Styles.pr_home_info_row}>
            </div>
            <Slideshow hide_controls={true} />
            <div className={Styles.pr_home_info_row}>
                <div className={Styles.pr_home_info_text}>
                    Learn More about the Heartland Association of Churches
                </div>
                <div>
                    <a href="about_us">
                        <img
                            alt="Learn More"
                            className={Styles.pr_home_info_image}
                            src={LearnMore}
                        />
                    </a>
                </div>
            </div>
            <div className={Styles.pr_home_info_row}>
                <div className={Styles.pr_home_info_text}>
                    Looking for a Christ-Loving, Bible-based Church?
                </div>
                <div>
                    <a href="find_a_church">
                        <img
                            alt="Find a Church"
                            className={Styles.pr_home_info_image}
                            src={FindAChurch}
                        />
                    </a>
                </div>
            </div>
            <div className={Styles.pr_home_info_row}>
                <div className={Styles.pr_home_info_text}>
                    Independent Church Looking for Fellowship and Mutual Support?
                </div>
                <div>
                    <a href="joining_heartland">
                        <img
                            alt="Join In"
                            className={Styles.pr_home_info_image}
                            src={JoinIn}
                        />
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Home;