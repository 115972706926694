import React from "react";
import Styles from "./styles/pr.module.css"
import Logo from "./images/heartlandaoc.jpg"
import {Link} from "react-router-dom";

const MinistryCredentialing = (props) => {
    document.title = "HAOC - " + props.page_title;

    return (<div>
        <h2>Ministry Credentialing</h2>
        <div className={Styles.wrapped_image}><img src={Logo} /></div>
        <div>
            <div className={Styles.haoc_larger_text_paragraph}>
                <div>The Heartland Association provides ministry credentialing for those engaged in pastoral ministry.
                    Two credentials are available:</div>
                <ol>
                    <li>License to Preach</li>
                    <li>Ordination</li>
                </ol>
            </div>
            <div className={Styles.haoc_larger_text_paragraph}>
                If you are interested in becoming a credentialed minister in the Heartland Association of Churches
                or transferring existing credentials, please contact us at
                <a href="mailto:contact@heartlandaoc.com">contact@heartlandaoc.com</a>.
            </div>
        </div>
    </div>)
}
export default MinistryCredentialing;