import React from "react";
import Styles from "./styles/pr.module.css"
import {Link} from "react-router-dom";

const Leaders = (props) => {
    document.title = "HAOC - " + props.page_title;

    return (<div>
        <h2>{props.page_title}</h2>
        <div>
            <div>
                <div className={Styles.pr_heading_medium}>Mike Polo, President</div>
                <div className={Styles.pr_block_quote}>

                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Drew Crowley, Vice-President</div>
                <div className={Styles.pr_block_quote}>

                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Charles Coakley, Secretary</div>
                <div className={Styles.pr_block_quote}>

                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Joe Davis</div>
                <div className={Styles.pr_block_quote}>

                </div>
            </div>
            <div>
                <div className={Styles.pr_heading_medium}>Mike Turner, Treasurer</div>
                <div className={Styles.pr_block_quote}>

                </div>
            </div>
        </div>
    </div>)
}
export default Leaders;